.add-product-to-wishlist-dialog {
    &.modal {
        padding: 0 1rem;

        .modal-dialog {
            top: 50%;
            margin: 0 auto;
            transform: translateY(-50%);
        }

        .modal-content {
            border: 0;
            padding: rem(16) rem(24) rem(24);

            .modal-body {
                line-height: rem(20);

                .add-product-wishlist-body {

                    .wishlist-modal-header {
                        margin-bottom: rem(32);
                        font-family: $crimson;
                        font-size: rem(24);
                        line-height: rem(32);
                    }

                    .default-wishlist {
                        text-transform: lowercase;
                        font-family: $gotham-regular;
                    }

                    .existing-list-collections {

                        .add-product-to-list {
                            .wishlist-radio-group {
                                max-height: rem(300);
                                overflow-y: auto;
                            }
                        }
                        .custom-radio {
                            font-family: $gotham-semibold;
                            font-size: rem(16);
                            line-height: rem(24);
                            margin-bottom: rem(16);
                            label {
                                line-height: rem(24);
                                padding-left: rem(12);
                                vertical-align: top;

                                &::before {
                                    top: rem(4);
                                }

                                &::after {
                                    top: rem(6);
                                }

                                svg {
                                    height: rem(12);
                                    vertical-align: baseline;
                                    width: rem(12);
                                }

                                .display-name {
                                    font-family: $gotham-semibold;
                                    font-size: rem(16);
                                    color: $emperor;
                                }
                            }
                        }

                        .add-list-item {
                            margin-top: rem(40);
                            line-height: rem(32);
                            font-weight: 500;
                        }
                    }

                    .create-list-collection-plp {
                        .create-wishlist-sub-header {
                            font-size: rem(14);
                            font-family: $gotham-semibold;
                            margin-bottom: rem(24);
                        }

                        .list-name-field {
                            margin-bottom: rem(40);
                        }

                        .btn {
                            font-weight: 500;
                            line-height: rem(32);
                        }
                    }

                    .close-list-popup {
                        text-align: center;
                        margin-top: rem(12);
                        line-height: rem(24);
                    }
                }
            }
        }
    }
}
