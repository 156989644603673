@import '~base/homePage';
@import './global/global';
@import './components/addProductToWishlistDialog';

.homepage {
    img {
        max-width: 100%;
    }

    .no-gutters {
        margin-right: rem(-16);
        margin-left: rem(-16);
    }

    .product-listing {
        margin-top: rem(104);
    }

    .recommendations {
        margin-top: 0;
    }
    
    .shop-dresses {
        margin-top: rem(104);
        margin-bottom: 0;

        .textbox-col {
            margin-bottom: 7%;
        }

        .shop-text-box {
            .title {
                color: $home-shop-text-color;
                font-family: $crimson;
                font-size: rem(32);
                line-height: rem(40);
                text-align: center;
            }

            .cta-btn {
                display: block;
                margin-top: rem(56);
                margin-left: rem(64);
                margin-right: rem(64);
                background: transparent;
                border-color: $home-shop-btn-border-color;
                color: $home-shop-text-color;
            }

            .cta-btn:hover {
                color: $home-shop-text-color;
            }
        }
    }

    .video-template {
        margin-top: rem(104);
        margin-bottom: 0;
    }

    .family-matching-dresses {
        margin-top: rem(104);

        .family-text-box {
            .title {
                color: $home-family-text-color;
                font-family: $crimson;
                font-size: rem(32);
                line-height: rem(40);
                text-align: center;
            }

            .cta-btn {
                display: block;
                background: transparent;
                border-color: $home-family-btn-border-color;
                color: $home-family-text-color;
                margin: auto;
                width: auto;
                min-width: 57%;
                height: auto;
                display: table;
                padding-left: rem(32);
                padding-right: rem(32);
                line-height: rem(24);
                padding-top: rem(16);
                padding-bottom: rem(16);
            }

            .cta-btn:hover {
                color: $home-family-text-color;
            }
        }
    }

    .homepage-category {
        margin-top: rem(104);
        padding-left: rem(16);
        padding-right: rem(16);

        .category-col {
            padding: 0;
        }

        #resize-btn {
            width: auto;
        }

        .category-col-button {
            margin: rem(48) auto rem(40);
            width: auto;
            height: auto;
            display: table;
            padding-left: rem(32);
            padding-right: rem(32);
            line-height: rem(24);
            padding-top: rem(16);
            padding-bottom: rem(16);
            max-width: 95%;
        }
    }

    .shop-the-print {
        margin-top: rem(104);

        .shop-text {
            .shop-text-heading {
                color: $home-column-heading-color;
                font-family: $gotham-semibold;
                font-size: rem(12);
                letter-spacing: rem(0.4);
                line-height: rem(16);
            }

            .shop-text-content {
                color: $home-column-content-color;
                font-family: $gotham-light;
                font-size: rem(24);
                line-height: rem(40);
            }
        }

        .btn {
            background: transparent;
            margin: 0 auto;
            margin-top: rem(48);
            border-color: $home-column-btn-border-color;
            color: $home-column-btn-link-color;
            font-size: rem(14);
            width: auto;
            min-width: 57%;
            height: auto;
            display: table;
            padding-left: rem(32);
            padding-right: rem(32);
            line-height: rem(24);
            padding-top: rem(16);
            padding-bottom: rem(16);

            &:hover {
                color: $home-column-btn-link-color;
            }
        }
    }

    .content-tile {
        margin-right: rem(-16);
        margin-left: rem(-16);
        padding-bottom: 0;

        .title {
            h1, h2, h3, h4, h5, h6 {
                text-decoration: none;
                color: $home-banner-title-color;
                font-family: $gotham-semibold;
                font-size: rem(24);
                line-height: rem(32);
            }
        }

        .cta-link {
            color: $home-banner-cta-color;
            font-family: $gotham-semibold;
            font-size: rem(16);
            line-height: rem(24);
        }
    }

    @include media-breakpoint-down(sm) {
        .product-listing {
            margin-top: rem(64);
        }

        .family-matching-dresses {
            margin-top: rem(64);

            .family-text-box {
                margin-left: rem(16);
                margin-right: rem(16);
                margin-top: rem(64);

                h1, h2, h3, h4, h5, h6 {
                    &.title {
                        font-family: $gotham-semibold;
                        font-size: rem(24);
                        line-height: rem(32);
                        text-align: center;
                        text-transform: uppercase;
                    }
                }
            }
        }

        .shop-dresses {
            margin-top: rem(64);
            margin-bottom: 0;

            .textbox-col {
                margin-bottom: 0;
            }

            .image-col {
                z-index: $zindex-low;
                top: rem(50);
            }

            .shop-text-box {
                margin-left: rem(16);
                margin-right: rem(16);
                margin-top: rem(64);

                .cta-btn {
                    margin: rem(32) rem(28) rem(104) rem(28);
                }

                h1, h2, h3, h4, h5, h6 {
                    &.title {
                        font-family: $gotham-semibold;
                        font-size: rem(24);
                        line-height: rem(32);
                        text-align: center;
                        text-transform: uppercase;
                    }
                }
            }
        }

        .video-template {
            margin-top: rem(64);
            margin-bottom: 0;
        }

        .homepage-category {
            margin-top: rem(64);
            padding-left: 0;
            padding-right: 0;

            .category-col-button {
                margin-top: rem(24);
                margin-bottom: rem(40);
            }
        }

        .shop-carousel {
            overflow: auto;

            > a {
                margin-right: rem(8);
                min-width: 84%;
                align-self: baseline;
            }

            > img {
                margin-right: rem(8);
                max-width: 84%;
                align-self: baseline;
            }
        }

        .shop-the-print {
            margin-top: rem(64);

            .btn {
                margin-top: rem(24);
                width: 75%;
            }
        }

        .product-listing > div {
            padding: 0 rem(4);
        }
    }
}
